<template>
  <div id="TTTQMForm">
    <div class="mb-4">
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="robo-14-400">
            <label class="mr-1"
              >{{ $t("multidisciplinary_board.lbl_patients_name") }}:</label
            >
            <span class="font-bold">{{ form.name }}</span>
          </div>
        </div>
        <div class="col-4">
          <div class="robo-14-400">
            <label class="capitalize mr-1"
              >{{ $t("multidisciplinary_board.lbl_age") }}:</label
            >
            <span class="font-bold">
              {{
                curr_disease &&
                curr_disease.patient_birthday &&
                mdtUtils.getAges(new Date(curr_disease.patient_birthday))
              }}
            </span>
          </div>
        </div>
        <div class="col-2">
          <div class="robo-14-400">
            <label class="mr-1"
              >{{ $t("multidisciplinary_board.lbl_genders") }}:</label
            >
            <span class="font-bold">{{ getGender(form.sex) }}</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="robo-14-400">
            {{ $t("multidisciplinary_board.lbl_address") }}: {{ form.address }}
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <label class="robo-14-400 mb-0 mr-2"
              >{{ $t("multidisciplinary_board.lbl_department") }}:</label
            >
            <input
              type="text"
              class="border fs-14 form-control bg-white leading-tight"
              v-model="form.dept"
              :readonly="hasSigned"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="overflow-hidden">
            <label class="float-left robo-14-400 mr-1 mb-0"
              >{{ $t("multidisciplinary_board.lbl_diagnose") }}:</label
            >
            <div class="robo-14-400" v-html="form && form.diagnostic"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <label class="robo-14-400 mb-0 mr-2 whitespace-nowrap"
              >{{ $t("multidisciplinary_board.lbl_mdt_at") }}:</label
            >
            <input
              type="datetime-local"
              class="border fs-14 form-control bg-white leading-tight"
              v-model="form.mdt_time"
              :readonly="hasSigned"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="fs-14 mb-2">
            {{ $t('multidisciplinary_board.lbl_mems_preside') }}:
            <span>{{ form.preside }}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="fs-14 mb-2">
            {{ $t('multidisciplinary_board.lbl_mems_type_secretary') }}:
            <span>{{ form.secretary }}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div
            class="fs-14 text-nowrap mb-0 mr-2"
          >{{ $t('multidisciplinary_board.lbl_participant') }}:</div>
          <div>
            <textarea class="form-control fs-14 bg-white border" v-model="form.members" rows="2"></textarea>
          </div>
        </div>
      </div>-->
      <div class="row mb-2">
        <div class="col-4 mb-2">
          <div class="fs-14 mb-0 mr-2 text-nowrap">
            {{ $t("multidisciplinary_board.lbl_admission_hospital_number") }}:
          </div>
          <div>
            <input
              type="text"
              class="border fs-14 form-control bg-white leading-tight"
              v-model="form.code"
              :readonly="hasSigned"
            />
          </div>
        </div>
        <div class="col-8">
          <div class="fs-14 mb-0 mr-2 text-nowrap">
            {{ $t("multidisciplinary_board.lbl_treat_duration") }}:
          </div>
          <div class="row">
            <div class="col-6">
              <div>
                <input
                  type="datetime-local"
                  class="border fs-14 form-control bg-white leading-tight"
                  v-model="form.treatment_time_start"
                  :readonly="hasSigned"
                />
              </div>
            </div>
            <div class="col-6">
              <div>
                <input
                  type="datetime-local"
                  class="border fs-14 form-control bg-white leading-tight"
                  v-model="form.treatment_time_end"
                  :readonly="hasSigned"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center mb-2">
      <p class="robo-14-400 mb-0 mr-2">Hội chẩn lúc:</p>
      <input type="datetime-local" class="border" v-model="form.mdt_time" />
    </div>-->
    <div class="mb-4">
      <div class="robo-16-500 mb-2">Chọn mẫu Thông qua mổ</div>
      <el-select
        allow-create
        clearable
        filterable
        v-model="doc_template_id"
        class="w-full"
        placeholder="Chọn mẫu phiếu"
        @change="handleChangeDocTemplate"
        remote
        :remote-method="getDocsTemplateList"
        :loading="loading"
      >
        <el-option
          v-for="item in docsTemplateList"
          :key="item.id + 'md'"
          :label="handleGetTemLabel(item)"
          :value="item.id"
        />
      </el-select>
    </div>
    <div class="m-0 mb-4">
      <div>
        <label for="txt-name" class="robo-16-500 font-bold uppercase">
          I.
          {{ $t("multidisciplinary_board.lbl_summary_medical") }}
        </label>
        <EditorInput
          id="medical_history-tqm"
          :prop_text="form.medical_history"
          :disabled="hasSigned"
          @onChangeContent="(content) => (form.medical_history = content)"
        />
      </div>
    </div>
    <div class="m-0 mb-4">
      <div>
        <div class="robo-16-500 fw-bold">
          <!-- <span class="text-uppercase me-1">Kết luận:</span>
          <span>(Sau khi đã khám lại và thảo luận)</span>-->
          <span class="uppercase">{{
            $t("multidisciplinary_board.lbl_conclusion")
          }}</span>
        </div>
        <EditorInput
          id="conclusion-tqm"
          :prop_text="form.conclusion"
          :disabled="hasSigned"
          @onChangeContent="(content) => (form.conclusion = content)"
        />
      </div>
    </div>
    <div class="robo-16-500 uppercase">
      <p>II. {{ $t("multidisciplinary_board.lbl_laboratory ") }}</p>
    </div>
    <div class="robo-16-500">
      <p>1. {{ $t("multidisciplinary_board.lbl_blood_test") }}</p>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">BC</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_bc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">Ure</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_ure"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold"
            >Bilirubin TP</label
          >
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_bilirubin_tp"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">HST</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_hst"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">Creatinin</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_creatinin"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold"
            >Bilirubin TP</label
          >
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_bilirubin_tt"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">HC</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_hc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">Glucose</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_glucose"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">GOT</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_got"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">HCT</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_hct"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">{{
            $t("multidisciplinary_board.lbl_blood")
          }}</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_blood"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">Protit</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_protit"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">GPT</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_gpt"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">TC</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_tc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold">PT%</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_pt"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="txt-name" class="robo-14-500 font-bold"
            >Cholesterol</label
          >
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            id="txt-name"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_cholesterol"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Hematocrit</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            placeholder
            v-model="form.test_hematocrit"
          />
        </div>
      </div>-->
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Đông máu CB</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_blood_basic"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Quick</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_quick"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">GGT</label>
          <input
            type="text"
            class="form-control mt-1 bg-white border"
            placeholder
            :readonly="hasSigned"
            v-model="form.test_ggt"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >2. {{ $t("multidisciplinary_board.lbl_urine") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.urine"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >3. {{ $t("multidisciplinary_board.lbl_chest_xray") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.xray"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          4.
          {{ $t("multidisciplinary_board.lbl_ellectrocardiogram") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.electrocar"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >5. {{ $t("multidisciplinary_board.lbl_ultrasound") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.ultrasound"
        />
      </div>
    </div>
    <div class="m-0 mb-4">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >6. {{ $t("multidisciplinary_board.lbl_endoscopic") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.endoscopy"
        />
      </div>
    </div>
    <div class="robo-16-500 uppercase">
      <p>III. {{ $t("multidisciplinary_board.lbl_preparationsurgical") }}</p>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          1.
          {{ $t("multidisciplinary_board.lbl_test_drug_reaction") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.drug_reaction"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          2.
          {{ $t("multidisciplinary_board.lbl_preoperative_antibiotics") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.antibiotics"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          3.
          {{ $t("multidisciplinary_board.lbl_surgical_method") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.surgery_method"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          4.
          {{ $t("multidisciplinary_board.lbl_anaesthetic_method") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.anaesthetic_method"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          5. {{ $t("multidisciplinary_board.lbl_surgery_team") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.surgical_team"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold">
          6.
          {{ $t("multidisciplinary_board.lbl_anaesthetic_team") }}
        </label>
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.anaesthetic_team"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >7. {{ $t("multidisciplinary_board.lbl_pathology") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.anatomic"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <div class="form-group col-6">
        <label for="txt-name" class="robo-16-500 font-bold"
          >8. {{ $t("multidisciplinary_board.lbl_blood_plan") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.blood_plan"
        />
      </div>
      <div class="form-group col-6">
        <label for="txt-name" class="robo-16-500 font-bold">{{
          $t("multidisciplinary_board.lbl_expected_date_of_surgery")
        }}</label>
        <input
          type="date"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.surgery_date"
        />
      </div>
    </div>
    <div class="m-0 mb-4">
      <div class="form-group">
        <label for="txt-name" class="robo-16-500 font-bold"
          >9. {{ $t("multidisciplinary_board.lbl_prognostic") }}</label
        >
        <input
          type="text"
          class="form-control mt-1 bg-white border"
          id="txt-name"
          placeholder
          :readonly="hasSigned"
          v-model="form.prognostic"
        />
      </div>
    </div>
    <div class="robo-16-500 uppercase">
      <p>IV. {{ $t("multidisciplinary_board.lbl_minute_signing") }}</p>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        <div class="form-group">
          <label for="txt-name" class="robo-16-500 font-bold">
            {{ $t("multidisciplinary_board.lbl_esthesiology_dept_mng") }}
          </label>
          <el-select
            class="w-full mt-2"
            :disabled="hasSigned"
            v-model="form.gm_sur_anes_id"
            placeholder=""
            @change="(item) => handleChangeDoctorType(item, 'gm_sur_anes_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="txt-name" class="robo-16-500 font-bold">
            {{ $t("multidisciplinary_board.lbl_surgery_dept_mng") }}
          </label>
          <el-select
            class="w-full mt-2"
            :disabled="hasSigned"
            v-model="form.gm_surgery_id"
            placeholder=""
            @change="(item) => handleChangeDoctorType(item, 'gm_surgery_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="txt-name" class="robo-16-500 font-bold">
            {{ $t("multidisciplinary_board.lbl_attending_doctor") }}
          </label>
          <el-select
            class="w-full mt-2"
            :disabled="hasSigned"
            v-model="form.treatment_doctor_id"
            placeholder=""
            @change="(item) => handleChangeDoctorType(item, 'doctor_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div
      class="my-3 float-right flex gap-2"
      :class="
        curr_disease.operation && curr_disease.operation.sign
          ? 'justify-between items-center float-none'
          : ''
      "
    >
      <div
        class="flex flex-wrap fs-14"
        v-if="curr_disease.operation && curr_disease.operation.sign"
      >
        <div>{{ curr_disease.operation.signer_name }} đã ký -&nbsp;</div>
        <div>{{ signAt }}</div>
      </div>
      <button
        v-if="
          curr_disease.operation &&
          !curr_disease.operation.sign &&
          curr_disease.status === 2
        "
        :disabled="!isPresident || loading || !curr_disease.operation"
        class="btn-action btn bg-pri bd-pri text-white"
        @click="handleSignTTTQM"
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          v-if="loading"
        ></span>
        <span v-else>{{ $t("multidisciplinary_board.btn_sign") }}</span>
      </button>
      <button
        v-if="curr_disease.status < 2"
        :disabled="loading"
        class="btn-action btn bg-pri bd-pri text-white px-5"
        @click="submit()"
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          v-if="loading"
        ></span>
        <span v-else>{{ $t("health_book_page.lbl_save") }}</span>
      </button>

      <button
        class="btn-action btn bg-pri bd-pri text-white"
        @click="showModalSaveDocTemplate(true)"
        :disabled="loading"
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          v-if="loading"
        ></span>
        <span v-else>{{ $t("multidisciplinary_board.btn_save_tem") }}</span>
      </button>
    </div>
    <div id="canvas-clone" class="hidden"></div>
    <TTTQMTemplate
      :form="form"
      :mdt_disease="curr_disease"
      :room_info="room_info"
      :save_pdf="save_pdf"
      @onSubmiting="onSubmiting"
    />
    <ModalSaveDocumentTemplate
      ref="ModalSaveDocumentTemplateTQM"
      :doc-content="docContent"
      :doc-type="MENU_EMR_TEMPLATE[2].value"
    />
  </div>
</template>

<script>
import TTTQMTemplate from '../../templates/HoiChan/TTTQMTemplate.vue'
import mdtUtils from '../../utils/mdtUtils'
import EditorInput from '@/components/EditorInput.vue'
import appUtils from '../../utils/appUtils'
import { mapState } from 'vuex'
import { SIGN_MDT_DISEASE } from '../../constants/appConstant'
import { DOCUMENT_SIGN_TYPES, MENU_EMR_TEMPLATE } from '../../utils/constants'
import moment from 'moment'
import ModalSaveDocumentTemplate from '../ElectronicMedicalRecords/ModalSaveDocumentTemplate.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'TTTQMForm',
  components: { TTTQMTemplate, EditorInput, ModalSaveDocumentTemplate },
  props: ['curr_disease', 'room_info'],
  data () {
    const defaultForm = {
      name: '',
      age: null,
      sex: '',
      address: '',
      code: '',
      dept: '',
      diagnostic: '',
      mdt_time: appUtils.formatInputDateTime() || '',
      treatment_progress: '',
      treatment_time: '',
      medical_history: '',
      test_bc: '',
      test_ure: '',
      test_bilirubin_tp: '',
      test_bilirubin_tt: '',
      test_hst: '',
      test_creatinin: '',
      test_hc: '',
      test_glucose: '',
      test_got: '',
      test_hct: '',
      test_blood: '',
      test_protit: '',
      test_gpt: '',
      test_tc: '',
      test_pt: '',
      test_cholesterol: '',
      urine: '',
      xray: '',
      electrocar: '',
      ultrasound: '',
      endoscopy: '',
      drug_reaction: '',
      antibiotics: '',
      surgery_desc: '',
      method: '',
      surgical_team: '',
      blood_plan: '',
      surgery_date: window.moment().format('YYYY-MM-DD'),
      prognostic: '',
      gm_sur_anes_name: '',
      gm_surgery_name: '',
      doctor_name: '',
      anaesthetic_method: '',
      anaesthetic_team: '',
      anatomic: '',
      preside: '',
      secretary: '',
      members: '',
      treatment_time_start: '',
      treatment_time_end: '',
      conclusion: '',
      test_hematocrit: '',
      test_blood_basic: '',
      test_quick: '',
      test_ggt: '',
      admission_room: '',
      admission_bed: '',
      surgery_method: '',
      treatment_doctor_id: null,
      gm_sur_anes_id: null,
      gm_surgery_id: null
    }

    return {
      defaultForm,
      form: defaultForm,
      save_pdf: 0,
      doctor_id: null,
      loading: false,
      mdtUtils,
      docsTemplateList: [],
      doc_template_id: null,
      MENU_EMR_TEMPLATE,
      doctors: []
    }
  },
  mounted () {
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id
  },
  watch: {
    curr_disease: {
      handler (d) {
        this.handleGetTTTQM(d)
        // this.processDisease(d)
      },
      deep: true
    },
    room_info (r) {
      if (r.clinic && !this.form.dept) {
        this.form.dept = r.clinic.name
      }
    },
    person (data) {
      if (data) {
        this.form.admission_room = this.person.admission_room
        this.form.admission_bed = this.person.admission_bed
      } else {
        this.form.admission_room = ''
        this.form.admission_bed = ''
      }
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person
    }),
    langId () {
      return appUtils.getLangId(this.$lang)
    },
    isPresident () {
      return this.doctor_id === this.room_info?.preside?.doctor_id
    },
    signAt () {
      return moment(this.curr_disease?.operation.sign?.sign_at).format(
        'HH:mm - DD/MM/YYYY'
      )
    },
    hasSigned () {
      return (
        Boolean(this.curr_disease) &&
        Boolean(
          this.curr_disease.concluding?.sign ||
            this.curr_disease.operation?.sign ||
            this.curr_disease.status === 2
        )
      )
    },
    docContent () {
      return {
        medical_history: {
          label:
            'Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc người bệnh',
          value: this.form.medical_history,
          editor: 1
        },
        conclusion: {
          label: 'Kêt luận',
          value: this.form.conclusion,
          editor: 1
        },
        treatment: {
          label: 'Hướng điều trị',
          value: this.form?.treatment,
          editor: 1
        },
        urine: {
          label: 'Nước tiểu',
          value: this.form.urine,
          editor: 0
        },
        xray: {
          label: 'X Quang',
          value: this.form.xray,
          editor: 0
        },
        electrocar: {
          label: 'Điện tim',
          value: this.form.electrocar,
          editor: 0
        },
        ultrasound: {
          label: 'Siêu âm',
          value: this.form.ultrasound,
          editor: 0
        },
        endoscopy: {
          label: 'Nội soi',
          value: this.form.endoscopy,
          editor: 0
        },
        drug_reaction: {
          label: 'Thử phản ứng thuốc',
          value: this.form.drug_reaction,
          editor: 0
        },
        antibiotics: {
          label: 'Kháng sinh trước mổ',
          value: this.form.antibiotics,
          editor: 0
        },
        surgery_method: {
          label: 'Phương pháp phẫu thuật',
          value: this.form.surgery_method,
          editor: 0
        },
        anaesthetic_method: {
          label: 'Phương pháp vô cảm',
          value: this.form.anaesthetic_method,
          editor: 0
        },
        surgical_team: {
          label: 'Kíp mổ',
          value: this.form.surgical_team,
          editor: 0
        },
        anaesthetic_team: {
          label: 'Kíp gây mê',
          value: this.form.anaesthetic_team,
          editor: 0
        },
        anatomic: {
          label: 'Giải phẫu bệnh',
          value: this.form.anatomic,
          editor: 0
        },
        blood_plan: {
          label: 'Dự trù máu',
          value: this.form.blood_plan,
          editor: 0
        },
        prognostic: {
          label: 'Dự kiến khó khăn tiên lượng',
          value: this.form.prognostic,
          editor: 0
        }
      }
    }
  },
  methods: {
    processDisease (d) {
      this.form = this.defaultForm

      if (d) {
        const diseaseCurrentLangData = d?.multilang?.find(
          (item) => item.lang === this.langId
        )

        const concludingCurrentLangData = d?.concluding?.multilang?.find(
          (item) => item.lang === this.langId
        )

        if (d?.medical_record) {
          this.form.code = d?.medical_record?.ref_id
        }

        if (d.operation) {
          const { ...newOperation } = d.operation
          this.form = {
            ...this.form,
            ...newOperation,
            gm_sur_anes_id: newOperation?.gm_sur_anes_id || null,
            gm_surgery_id: newOperation?.gm_surgery_id || null,
            treatment_doctor_id: newOperation?.treatment_doctor_id || null,
            // age: this.getAges(d.patient_birthday) || null,
            code: newOperation.code || '',
            diagnostic:
              // newOperation.diagnostic ||
              (diseaseCurrentLangData &&
                diseaseCurrentLangData?.patient_diagnostic) ||
              d.patient_diagnostic ||
              '',
            mdt_time: newOperation.mdt_time
              ? appUtils.formatInputDateTime(newOperation.mdt_time)
              : appUtils.formatInputDateTime(this.room_info.start_time),
            surgery_date: newOperation.surgery_date
              ? window
                .moment(newOperation.surgery_date, 'YYYY-MM-DDTHH:mm:ssZ')
                .format('YYYY-MM-DD')
              : window.moment().format('YYYY-MM-DD'),
            medical_history: newOperation.medical_history || '',
            conclusion: newOperation.conclusion || '',
            preside: newOperation?.preside || d?.preside || '',
            secretary:
              newOperation?.doctor_name ||
              d.doctor?.name ||
              this.pre?.doctor?.name ||
              '',
            members: newOperation?.members || this.form.members || '',
            treatment_time_start:
              appUtils.formatInputDateTime(
                newOperation?.treatment_time_start
              ) ||
              appUtils.formatInputDateTime(d?.treatment_time_start) ||
              '',
            treatment_time_end:
              appUtils.formatInputDateTime(newOperation?.treatment_time_end) ||
              appUtils.formatInputDateTime(d?.treatment_time_end) ||
              ''
          }
        } else {
          this.form = {
            ...this.defaultForm,
            name: d.patient_name,
            // age: this.getAges(d.patient_birthday) || null,
            sex: d.patient_gender,
            address: d.patient_address,
            code:
              d?.medical_record?.ref_id || d?.concluding?.profile_code || '',
            diagnostic:
              (diseaseCurrentLangData &&
                diseaseCurrentLangData?.patient_diagnostic) ||
              d.patient_diagnostic ||
              '',
            medical_history:
              (concludingCurrentLangData &&
                concludingCurrentLangData.treatment_progress) ||
              d.patient_diseases_current_cure ||
              (diseaseCurrentLangData &&
                diseaseCurrentLangData.patient_diseases_current_cure) ||
              '',
            conclusion:
              (concludingCurrentLangData &&
                concludingCurrentLangData.conclusion) ||
              d.conclusion ||
              (diseaseCurrentLangData && diseaseCurrentLangData.conclusion) ||
              '',
            mdt_time: d.mdt_date
              ? appUtils.formatInputDateTime(d.mdt_date)
              : appUtils.formatInputDateTime(),
            dept: this.form.dept || this.room_info?.clinic?.name || '',
            surgery_date: window.moment().format('YYYY-MM-DD'),
            doctor_name: d.doctor?.name,
            preside: d?.preside || this.pre?.doctor?.name || '',
            secretary: d.doctor?.name || '',
            members: this.form.members || '',
            treatment_time_start: d.admission_date
              ? appUtils.formatInputDateTime(d.admission_date)
              : '',
            treatment_time_end: d.mdt_date
              ? appUtils.formatInputDateTime(d.mdt_date)
              : ''
          }
        }
      }
    },
    submit () {
      this.loading = true
      this.$emit('onLoading', true)
      this.save_pdf++
    },
    onSubmiting (base64) {
      if (!this.curr_disease) return
      if (this.curr_disease.operation) {
        this.updateMdtDiseaseOperation(base64)
      } else {
        this.createMdtDiseaseOperation(base64)
      }
    },
    async savePdfToS3 (base64, operationId = this.curr_disease?.operation?.id) {
      if (!base64 || !operationId) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('TTTQM')} ${operationId} ${window
                .moment(this.curr_disease?.operation?.created_at)
                .format('YYYY-MM-DD')}`
            )}_` || 'operation_',
          path: 'room_disease_operation',
          type: DOCUMENT_SIGN_TYPES.CODE.DISEASE_OPERATION,
          type_id: operationId,
          uuid: ''
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          // this.loading = false
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async createMdtDiseaseOperation (base64 = null) {
      let self = this
      let params = { ...this.form }
      params.mdt_room_disease_id = self.curr_disease.id
      params.mdt_time = params.mdt_time
        ? window.moment(params.mdt_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null
      params.surgery_date = params.surgery_date
        ? window
          .moment(params.surgery_date)
          .add(7, 'hours')
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time = params.treatment_time
        ? window.moment(params.treatment_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_end = params.treatment_time_end
        ? window
          .moment(params.treatment_time_end)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_start = params.treatment_time_start
        ? window
          .moment(params.treatment_time_start)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      // let formData = new FormData()
      // for (var key in params) {
      //   formData.append(key, params[key] || '')
      // }
      const res = await self.$rf
        .getRequest('DoctorRequest')
        .createMdtOperation(params)

      if (res.data) {
        await self.savePdfToS3(base64, res.data?.id).finally(() => {
          this.doc_template_id = null
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.loading = false
          self.$emit('refresh')
        })
      }
    },
    async updateMdtDiseaseOperation (base64 = null) {
      let self = this
      if (!self.curr_disease?.operation) {
        return
      }

      let params = { ...this.form }
      params.mdt_room_disease_id = self.curr_disease.id
      params.mdt_time = params.mdt_time
        ? window.moment(params.mdt_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null
      params.surgery_date = params.surgery_date
        ? window
          .moment(params.surgery_date)
          .add(7, 'hours')
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time = params.treatment_time
        ? window.moment(params.treatment_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_end = params.treatment_time_end
        ? window
          .moment(params.treatment_time_end)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_start = params.treatment_time_start
        ? window
          .moment(params.treatment_time_start)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      // let formData = new FormData()

      // for (var key in params) {
      //   formData.append(key, params[key] || '')
      // }

      const res = await self.$rf
        .getRequest('DoctorRequest')
        .updateMdtOperation(self.curr_disease.operation.id, params)

      if (res.status === 200) {
        await self
          .savePdfToS3(base64, self.curr_disease.operation.id)
          .finally(() => {
            this.doc_template_id = null
            self.$toast.open({
              message: 'Lưu thành công',
              type: 'success'
            })
            self.loading = false
            self.$emit('refresh')
          })
      }
    },
    getAges (date) {
      return mdtUtils.getAges(new Date(date))
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    async handleSignTTTQM () {
      const params = {
        id: this.curr_disease?.operation?.id,
        type: SIGN_MDT_DISEASE.TTTQM,
        method: 1
      }

      const r = confirm(
        this.$t('multidisciplinary_board.lbl_approve_confirm_msg')
      )

      if (!r) return

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_signed_msg'),
          type: 'success'
        })
        this.$emit('refresh')
      } catch (error) {
        console.log({ error })
      }
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[2].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then((r) => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              (item) =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find((item) => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}

      for (const [key, value] of Object.entries(contentObj)) {
        contentObj[key] = value?.value
      }

      this.form = {
        ...this.form,
        ...contentObj,
        patient_diseases_current_cure: contentObj.medical_history
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    showModalSaveDocTemplate (show) {
      this.$refs.ModalSaveDocumentTemplateTQM.showModalSaveDocTemplate(show)

      // window.$('#modalSaveDocTemplate-3').modal(show ? 'show' : 'hide')
    },
    openModal (show) {
      if (show) {
        this.handleGetTTTQM(this.curr_disease)
        this.getDocsTemplateList()
        this.getDoctors()
      }
    },
    async handleGetTTTQM (curr_disease) {
      let clonedDisease = this.curr_disease

      try {
        if (!curr_disease?.operation?.id) return
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMdtOperationByID(curr_disease?.operation?.id)
        clonedDisease = cloneDeep({
          ...this.curr_disease,
          operation: response.data
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.processDisease(clonedDisease)
      }
    },
    async getDoctors () {
      try {
        const clinic_id = this.$globalClinic?.id
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeDoctorType (item, key) {
      const doctorInfo =
        this.doctors?.find((doctor) => doctor?.user_id === item) || {}
      this.form[key] = doctorInfo?.name
    }
  }
}
</script>

<style scoped>
.robo-18-500 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-14-400 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.robo-14-500 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-16-500 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.fs-20 {
  font-size: 20px;
}

.btn-action {
  width: 150px;
}
</style>
